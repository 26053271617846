import React from 'react';
import { PageProps } from 'gatsby';
import { Layout } from 'components/shared';
import { CookiePolicyLayout } from 'components/Policy';

const PrivacyPolicy: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <CookiePolicyLayout />
    </Layout>
  );
};

export default PrivacyPolicy;
